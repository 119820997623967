import { MerchantPage } from './pages/Merchant.page';
export var merchantPermissions = [];
export var merchantRoutes = [
    {
        id: 'merchant',
        path: '/merchant/:id',
        component: MerchantPage,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';
export * from './hooks';
