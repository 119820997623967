var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Flex } from '@chakra-ui/react';
import { fromUnixTime, getUnixTime } from 'date-fns';
import { useQueryModel } from '@obtainly/http';
import { ContentLoader, ListMaker, MonthYearPicker } from 'src/core/components';
import { useAtom, userAtom, distributorAtom } from 'src/core/store';
import { LedgerLogItem } from 'src/ledger';
export var MerchantPaymentHistory = function (props) {
    var _a, _b;
    var now = new Date();
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorAtom)[0];
    var _c = React.useState(getUnixTime(now)), date = _c[0], setDate = _c[1];
    var payload = {
        _id: (props === null || props === void 0 ? void 0 : props.merchantId) || (user === null || user === void 0 ? void 0 : user._id),
        distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
        month: fromUnixTime(date).getMonth() + 1,
        year: fromUnixTime(date).getFullYear(),
    };
    var _d = useQueryModel('merchant.statement', payload, {
        enabled: !!payload._id,
    }), data = _d.data, isLoading = _d.isLoading;
    var transactions = ((_b = (_a = data === null || data === void 0 ? void 0 : data.payments) === null || _a === void 0 ? void 0 : _a.list) === null || _b === void 0 ? void 0 : _b.filter(function (item) { return item.value; })) || [];
    return (_jsxs(_Fragment, { children: [_jsx(Flex, __assign({ px: "15px", py: "10px", gridGap: 2 }, { children: _jsx(MonthYearPicker, { value: date, onChange: function (value) { return setDate(value); } }, void 0) }), void 0), _jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !(transactions === null || transactions === void 0 ? void 0 : transactions.length), errorSummary: "No transactions found" }, void 0), !isLoading && (transactions === null || transactions === void 0 ? void 0 : transactions.length) ? (_jsx(ListMaker, { data: transactions.slice().reverse(), component: _jsx(LedgerLogItem, {}, void 0) }, void 0)) : null] }, void 0));
};
