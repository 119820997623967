var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Flex, Box, Text, VStack } from '@chakra-ui/layout';
import { Button, useTheme } from '@chakra-ui/react';
import { FiCopy } from 'react-icons/fi';
import { IoAlertCircle, IoCloseCircle, IoCheckmarkCircle, IoInformationCircle, } from 'react-icons/io5';
import { OrderPaymentStatus, OrderTag } from '@obtainly/models';
import { Card, CardBody } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { useAtom, distributorAtom } from 'src/core/store';
import { parseVirtualAccount } from 'src/ledger';
export var OrderNotifications = function (_a) {
    var _b;
    var order = _a.order;
    var toast = useToast().toast;
    var distributor = useAtom(distributorAtom)[0];
    var merchant = order === null || order === void 0 ? void 0 : order.merchant;
    var customerDonPay = React.useMemo(function () {
        return (order === null || order === void 0 ? void 0 : order.payment_status) === OrderPaymentStatus.Paid;
    }, [order === null || order === void 0 ? void 0 : order.payment_status]);
    var customerFitAffordam = React.useMemo(function () {
        var _a;
        if ((distributor === null || distributor === void 0 ? void 0 : distributor._id) && (order === null || order === void 0 ? void 0 : order.amount)) {
            return (((_a = merchant === null || merchant === void 0 ? void 0 : merchant.ledgers) === null || _a === void 0 ? void 0 : _a[(distributor === null || distributor === void 0 ? void 0 : distributor._id) || 'wallet'].balance) >
                (order === null || order === void 0 ? void 0 : order.amount));
        }
        return false;
    }, [distributor === null || distributor === void 0 ? void 0 : distributor._id, merchant === null || merchant === void 0 ? void 0 : merchant.ledgers, order === null || order === void 0 ? void 0 : order.amount]);
    var virtualAccount = React.useMemo(function () {
        var _a;
        var tagKey = "virtual_account|".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id);
        var virtualAccount = (_a = merchant === null || merchant === void 0 ? void 0 : merchant.ledger_tags) === null || _a === void 0 ? void 0 : _a[tagKey];
        return parseVirtualAccount(virtualAccount);
    }, [merchant === null || merchant === void 0 ? void 0 : merchant.ledger_tags, distributor === null || distributor === void 0 ? void 0 : distributor._id]);
    return (_jsx(Card, { children: _jsx(CardBody, { children: _jsxs(VStack, __assign({ spacing: 0, position: "relative", align: "stretch" }, { children: [_jsx(OrderNotificationItem, { status: "success", title: "This order has been placed successfully", summary: _jsxs(_Fragment, { children: ["This order has been sent to ", _jsx("b", { children: distributor === null || distributor === void 0 ? void 0 : distributor.name }, void 0)] }, void 0) }, void 0), customerDonPay ? (_jsx(OrderNotificationItem, { status: "success", title: "Great! You have paid for this order", isHidden: true }, void 0)) : customerFitAffordam ? (_jsx(OrderNotificationItem, { status: "info", title: "Your balance is sufficient to pay for this order", isHidden: true }, void 0)) : null, (order === null || order === void 0 ? void 0 : order.tag) === OrderTag.Pending ? (_jsx(OrderNotificationItem, __assign({ status: "warning", title: "Make payment to the account specified below:", isLast: true }, { children: _jsxs(Box, { children: [_jsxs(Text, __assign({ mt: 3 }, { children: ["Bank: ", (_b = virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.bank) === null || _b === void 0 ? void 0 : _b.name] }), void 0), _jsxs(Flex, __assign({ alignItems: "center", justifyContent: "space-between" }, { children: [_jsxs(Box, { children: [_jsx(Text, __assign({ as: "span" }, { children: "Account No.: " }), void 0), _jsx(Text, __assign({ as: "span", fontWeight: "medium" }, { children: virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.account_number }), void 0)] }, void 0), _jsx(Button, __assign({ size: "sm", colorScheme: "primary", variant: "ghost", leftIcon: _jsx(FiCopy, { size: 16 }, void 0), onClick: function () {
                                                var _a, _b;
                                                (_b = (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText) === null || _b === void 0 ? void 0 : _b.call(_a, virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.account_number);
                                                toast({
                                                    status: 'info',
                                                    description: 'Account number copied!',
                                                });
                                            } }, { children: "Copy" }), void 0)] }), void 0), _jsxs(Text, { children: ["Account Name: ", virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.account_name] }, void 0)] }, void 0) }), void 0)) : (order === null || order === void 0 ? void 0 : order.tag) === OrderTag.Processing ? (_jsx(OrderNotificationItem, { status: "info", title: "This order is being processed by ".concat(distributor === null || distributor === void 0 ? void 0 : distributor.name), summary: "You'll be notified by ".concat(distributor === null || distributor === void 0 ? void 0 : distributor.name, " once your order has been sent out for delivery.") }, void 0)) : (order === null || order === void 0 ? void 0 : order.tag) === OrderTag.InTransit ? (_jsx(OrderNotificationItem, { status: "info", title: "This order has been sent out for delivery" }, void 0)) : (order === null || order === void 0 ? void 0 : order.tag) === OrderTag.Cancelled ? (_jsx(OrderNotificationItem, { status: "critical", title: "This order has been cancelled", isLast: true }, void 0)) : (order === null || order === void 0 ? void 0 : order.tag) === OrderTag.Delivered ? (_jsx(OrderNotificationItem, { status: "success", title: "This order has been delivered", isLast: true }, void 0)) : null] }), void 0) }, void 0) }, void 0));
};
var OrderNotificationItem = function (props) {
    var ICON_SIZE = 32;
    var colors = useTheme().colors;
    var Icon = props.status === 'warning'
        ? IoAlertCircle
        : props.status === 'info'
            ? IoInformationCircle
            : props.status === 'critical'
                ? IoCloseCircle
                : IoCheckmarkCircle;
    return (_jsxs(Flex, __assign({ gridGap: 3, position: "relative", display: props.isHidden ? 'none' : 'flex', alignItems: !!(props === null || props === void 0 ? void 0 : props.summary) || !!props.children ? 'flex-start' : 'center', pb: (props === null || props === void 0 ? void 0 : props.isLast) ? 0 : 3, css: {
            '::before': {
                content: '" "',
                position: 'absolute',
                top: "".concat(ICON_SIZE, "px"),
                bottom: '14rem',
                left: "".concat(ICON_SIZE / 2, "px"),
                width: '1px',
                height: "calc(100% - ".concat(ICON_SIZE, "px)"),
                display: (props === null || props === void 0 ? void 0 : props.isLast) ? 'none' : 'block',
                borderLeft: "2px dashed ".concat(colors[props.status]['200']),
            },
        } }, { children: [_jsx(Box, { children: _jsx(Icon, { size: ICON_SIZE, color: colors[props.status]['600'] }, void 0) }, void 0), _jsxs(Box, { children: [_jsx(Text, __assign({ fontWeight: "medium" }, { children: props.title }), void 0), !!props.summary && (_jsx(Text, __assign({ color: "gray.600", fontSize: "sm" }, { children: props.summary }), void 0)), props.children] }, void 0)] }), void 0));
};
