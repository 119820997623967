import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useQueryModel } from '@obtainly/http';
import { ContentLoader } from 'src/core/components';
import { useAtom, userAtom, distributorAtom } from 'src/core/store';
import { OrdersList } from 'src/order';
export var MerchantOrders = function (props) {
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorAtom)[0];
    var _a = useQueryModel('order.read_by_merchant_distributor', {
        merchant_id: (props === null || props === void 0 ? void 0 : props.merchantId) || (user === null || user === void 0 ? void 0 : user._id),
        distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
    }), orders = _a.data, isLoading = _a.isLoading;
    return (_jsxs(_Fragment, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !(orders === null || orders === void 0 ? void 0 : orders.length) }, void 0), !isLoading ? _jsx(OrdersList, { orders: orders || [] }, void 0) : null] }, void 0));
};
