var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Flex } from '@chakra-ui/react';
import { useQueryModel } from '@obtainly/http';
import React from 'react';
import { useParams } from 'react-router';
import { Layout } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
import { MerchantPageContent, MerchantDetails } from '../components';
export var MerchantPage = function () {
    var router = useRouter();
    var merchantId = useParams().id;
    var _a = useQueryModel('merchant.read', { _id: merchantId }, { enabled: !!merchantId }), merchants = _a.data, isLoading = _a.isLoading;
    var merchant = React.useMemo(function () {
        if (!(merchants === null || merchants === void 0 ? void 0 : merchants.length))
            return null;
        return merchants[0];
    }, [merchants]);
    return (_jsx(Layout, __assign({ headerProps: {
            title: isLoading ? '...' : (merchant === null || merchant === void 0 ? void 0 : merchant.business_name) || 'Customer',
        } }, { children: !!merchant && (_jsxs(_Fragment, { children: [_jsx(Flex, __assign({ mb: "10px" }, { children: _jsx(Button, __assign({ onClick: function () {
                            return router.go('order', { id: 'new' }, { merchant_id: merchantId });
                        }, w: "full", colorScheme: "primary" }, { children: "Place Order" }), void 0) }), void 0), _jsx(MerchantDetails, { merchantId: merchantId }, void 0), _jsx(MerchantPageContent, { merchant: merchant }, void 0)] }, void 0)) }), void 0));
};
