var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Error404 } from 'src/core/pages/404';
import { dashboardRoutes } from 'src/dashboard';
import { merchantRoutes } from 'src/merchant';
import { orderRoutes } from 'src/order';
import { productRoutes } from 'src/product';
import { statementRoutes } from 'src/statement';
import { userRoutes } from 'src/user';
var resolvedRoutes = [];
var resolveRouteGroup = function (routeGroup) {
    if (routeGroup === null || routeGroup === void 0 ? void 0 : routeGroup.length) {
        resolvedRoutes = __spreadArray(__spreadArray([], resolvedRoutes, true), routeGroup, true);
    }
};
resolveRouteGroup(dashboardRoutes);
resolveRouteGroup(merchantRoutes);
resolveRouteGroup(orderRoutes);
resolveRouteGroup(productRoutes);
resolveRouteGroup(statementRoutes);
resolveRouteGroup(userRoutes);
export var routes = __spreadArray(__spreadArray([], resolvedRoutes, true), [
    {
        id: 'error_404',
        path: '*',
        component: Error404,
    },
], false);
