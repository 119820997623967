import React from 'react';
import { configAtom, useAtom } from 'src/core/store';
import { useQueryModel } from '@obtainly/http';
export var AppVersionStatus;
(function (AppVersionStatus) {
    AppVersionStatus["OLD"] = "old";
    AppVersionStatus["LATEST"] = "latest";
    AppVersionStatus["UNSET"] = "unset";
    AppVersionStatus["UNKNOWN"] = "unknown";
})(AppVersionStatus || (AppVersionStatus = {}));
export function useAppVersion(key) {
    var appVersion = useAtom(configAtom)[0].appVersion;
    var _a = React.useState(new Date()), refresh = _a[0], setRefresh = _a[1];
    var _b = useQueryModel('config.read_by_key', { key: key || 'SALES_APP_VERSION.default' }, {
        cacheTime: 0,
        enabled: !!appVersion,
    }), data = _b.data, isLoading = _b.isLoading, isError = _b.isError, refetch = _b.refetch, isRefetching = _b.isRefetching;
    var appVersionStatus = isError
        ? AppVersionStatus.UNKNOWN
        : (data === null || data === void 0 ? void 0 : data.length)
            ? data[0].value === appVersion
                ? AppVersionStatus.LATEST
                : AppVersionStatus.OLD
            : AppVersionStatus.UNSET;
    React.useEffect(function () {
        // set interval to refresh after every x mins
        var interval = 5;
        var timer = null;
        if (refresh && appVersionStatus !== AppVersionStatus.OLD) {
            timer = setTimeout(function () {
                setRefresh(new Date());
                refetch();
            }, interval * 6e4);
        }
        return function () {
            timer && clearTimeout(timer);
        };
    }, [refresh]); // eslint-disable-line
    return {
        appVersionStatus: appVersionStatus,
        loadingAppVersion: isLoading || isRefetching,
    };
}
