var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Checkbox } from '@chakra-ui/checkbox';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormikField, FormGroup } from '@obtainly/ui/web-shared/FormField';
import { useToast, useLogin } from 'src/core/hooks';
var loginFormSchema = Yup.object({
    email: Yup.string()
        .required('Email is required.')
        .email('Email must be valid.'),
    hash: Yup.string().required('Password field is required'),
});
export var LoginForm = function () {
    var toast = useToast().toast;
    var doLogin = useLogin();
    var initialValues = {
        email: '',
        phone: '',
        hash: '',
        remember: true,
    };
    var onSubmit = function (values) {
        var _a;
        doLogin.mutate({
            phone: (_a = (values.phone || values.email)) === null || _a === void 0 ? void 0 : _a.trim(),
            hash: values.hash,
            remember: values.remember,
        });
    };
    React.useEffect(function () {
        doLogin.isError &&
            toast({
                status: 'error',
                description: doLogin.error.message,
            });
    }, [doLogin.isError, doLogin.error, toast]);
    return (_jsx(_Fragment, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: loginFormSchema, onSubmit: onSubmit, validateOnMount: true }, { children: function (formik) { return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(FormGroup, __assign({ label: "Email" }, { children: _jsx(FormikField, { autoComplete: "off", name: "email", type: "email", placeholder: "Enter your email" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Password" }, { children: _jsx(FormikField, { type: "password", name: "hash", placeholder: "Enter your password" }, void 0) }), void 0), _jsx(FormGroup, { children: _jsx(Field, __assign({ as: Checkbox, name: "remember", colorScheme: "primary", isChecked: formik.values.remember }, { children: "Keep me logged in" }), void 0) }, void 0), _jsx(Button, __assign({ mt: 2, type: "submit", colorScheme: "primary", isDisabled: doLogin.isLoading || !formik.isValid, isLoading: doLogin.isLoading, loadingText: "Logging you in", isFullWidth: true }, { children: "Continue" }), void 0)] }), void 0)); } }), void 0) }, void 0));
};
