import { atom, useAtom as useAtomNative } from 'jotai';
import { getUnixTime } from 'date-fns';
import { getSessionToken } from '@obtainly/http';
export var useAtom = useAtomNative;
export var userAtom = atom(null);
export var permissionsAtom = atom([]);
export var userIdAtom = atom(null);
export var distributorAtom = atom(null);
export var authAtom = atom({
    token: getSessionToken(),
    isLoading: false,
    refreshedAt: null,
    activeDate: getUnixTime(new Date()),
});
export var configAtom = atom(function () { return ({
    appVersion: '0.6.1',
    appEnv: process.env.REACT_APP_ENV || null,
    defaultLocationId: '61702ac2b671e97fae3f38c6',
    defaultStoreTypeId: '61702a8246167179457cfcf2',
    defaultUploadId: '5d56e06b363ab89e65204676',
}); });
