var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TabPanel, TabPanels } from '@chakra-ui/react';
import React from 'react';
import { Card, PageTabs } from 'src/core/components';
import { MerchantPaymentHistory } from '.';
import { MerchantOrders } from './MerchantOrders';
export var MerchantPageContent = function (_a) {
    var merchant = _a.merchant;
    var _b = React.useState(0), activeTab = _b[0], setActiveTab = _b[1];
    var tabs = ['Orders', 'Payments'];
    return (_jsx(Card, __assign({ my: "10px" }, { children: _jsx(PageTabs, __assign({ index: activeTab, onChange: function (idx) { return setActiveTab(idx); }, tabs: tabs }, { children: _jsxs(TabPanels, __assign({ tabIndex: activeTab }, { children: [_jsx(TabPanel, __assign({ p: 0 }, { children: _jsx(MerchantOrders, { merchantId: merchant === null || merchant === void 0 ? void 0 : merchant._id }, void 0) }), void 0), _jsx(TabPanel, __assign({ p: 0 }, { children: _jsx(MerchantPaymentHistory, { merchantId: merchant === null || merchant === void 0 ? void 0 : merchant._id }, void 0) }), void 0)] }), void 0) }), void 0) }), void 0));
};
