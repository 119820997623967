var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Avatar, Badge, Box, Button, Flex, Grid, GridItem, Heading, Text, } from '@chakra-ui/react';
import { useQueryModel } from '@obtainly/http';
import { formatCurrency } from '@obtainly/utils';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useRouter } from 'src/core/hooks';
import { useAtom, userAtom } from 'src/core/store';
import { Card, CardBody, ContentLoader } from 'src/core/components';
import { useMerchantDashboard } from '../../merchant';
import { analytics } from 'src/core/utils';
export var MerchantGrid = function () {
    var user = useAtom(userAtom)[0];
    var _a = useQueryModel('merchant.read_by_distributor_officer', {
        distributor_id: user === null || user === void 0 ? void 0 : user.distributor_id,
        distributor_officer_id: user === null || user === void 0 ? void 0 : user._id,
    }, {
        enabled: !!(user === null || user === void 0 ? void 0 : user._id) && !!(user === null || user === void 0 ? void 0 : user.distributor_id),
    }), data = _a.data, isLoading = _a.isLoading;
    return (_jsxs(Box, __assign({ p: "10px" }, { children: [_jsx(Box, { children: _jsx(Heading, __assign({ fontSize: "lg", mb: "10px" }, { children: "Customers" }), void 0) }, void 0), _jsx(ContentLoader, { isLoading: isLoading, errorSummary: "No customers", contentUnavailable: !(data === null || data === void 0 ? void 0 : data.length) }, void 0), _jsx(Grid, __assign({ templateColumns: "1fr 1fr", gridGap: "10px" }, { children: (data === null || data === void 0 ? void 0 : data.length) &&
                    data.map(function (merchant) { return (_jsx(GridItem, { children: _jsx(MerchantGridItem, { merchant: merchant }, void 0) }, merchant._id)); }) }), void 0)] }), void 0));
};
var MerchantGridItem = function (_a) {
    var _b, _c;
    var merchant = _a.merchant;
    var CLICK_SOURCE = 'DashboardMerchantGrid';
    var user = useAtom(userAtom)[0];
    var router = useRouter();
    var dashboard = useMerchantDashboard(merchant._id, user === null || user === void 0 ? void 0 : user.distributor_id).data;
    return (_jsx(Card, __assign({ h: "100%" }, { children: _jsx(CardBody, { children: _jsx(Link, __assign({ to: router.getLink('merchant', { id: merchant._id }) }, { children: _jsxs(Flex, __assign({ flexDir: "column", align: "center", gridGap: 1, onClick: function () {
                        analytics.trackClick({
                            '!Click ID': 'View Merchant',
                            '!Click Source': CLICK_SOURCE,
                        });
                        router.go('merchant', { id: merchant._id });
                    } }, { children: [_jsx(Avatar, { size: "md", borderRadius: "md", name: String((_c = (_b = merchant.business_name) === null || _b === void 0 ? void 0 : _b.split(' ')) === null || _c === void 0 ? void 0 : _c[0]) }, void 0), _jsx(Text, __assign({ mt: "8px", fontSize: "md", fontWeight: "medium", textAlign: "center", noOfLines: 1 }, { children: merchant.business_name }), void 0), _jsxs(Text, __assign({ color: "gray.700", fontSize: "sm" }, { children: ["Target", ' ', formatCurrency((dashboard === null || dashboard === void 0 ? void 0 : dashboard.sales_target) || 0, {
                                    showCurrency: true,
                                    compact: true,
                                })] }), void 0), _jsxs(Flex, __assign({ color: "gray.700", fontSize: "sm", gridGap: 2 }, { children: [_jsxs(Badge, { children: [(dashboard === null || dashboard === void 0 ? void 0 : dashboard.achieved_pc) || 0, "%"] }, void 0), _jsx(Text, { children: "achieved" }, void 0)] }), void 0), _jsx(Button, __assign({ size: "sm", leftIcon: _jsx(FiPlus, {}, void 0), variant: "ghost", colorScheme: "primary", onClick: function (e) {
                                e.preventDefault();
                                e.stopPropagation();
                                router.go('order', { id: 'new' }, { merchant_id: merchant._id });
                            } }, { children: "New Order" }), void 0)] }), void 0) }), void 0) }, void 0) }), void 0));
};
