var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Avatar, Badge, Box, Button, Center, Divider, Flex, SimpleGrid, Text, useBoolean, VStack, } from '@chakra-ui/react';
import { FiChevronRight, FiCopy } from 'react-icons/fi';
import { RiBankFill, RiMapPin2Fill, RiPhoneFill, RiPieChart2Fill, RiWalletFill, } from 'react-icons/ri';
import { useQueryModel } from '@obtainly/http';
import { formatCurrency } from '@obtainly/utils';
import { useToast } from 'src/core/hooks';
import { distributorAtom, useAtom, userAtom } from 'src/core/store';
import { Card, CardBody, ContentLoader } from 'src/core/components';
import { parseVirtualAccount } from 'src/ledger';
import { Link } from 'react-router-dom';
import { useMerchantDashboard } from '..';
export var MerchantDetails = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var merchantId = _a.merchantId, miniView = _a.miniView;
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorAtom)[0];
    var toast = useToast().toast;
    var _h = useQueryModel('merchant.read', { _id: merchantId }, { enabled: !!merchantId }), merchants = _h.data, isLoading = _h.isLoading;
    var merchant = React.useMemo(function () {
        if (!(merchants === null || merchants === void 0 ? void 0 : merchants.length))
            return null;
        return merchants[0];
    }, [merchants]);
    var balance = formatCurrency(((_c = merchant === null || merchant === void 0 ? void 0 : merchant.ledgers["".concat((_b = user === null || user === void 0 ? void 0 : user.distributor) === null || _b === void 0 ? void 0 : _b._id)]) === null || _c === void 0 ? void 0 : _c.balance) || 0, { showCurrency: true });
    var virtualAccount = React.useMemo(function () {
        var _a;
        var tagKey = "virtual_account|".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id);
        var virtualAccount = (_a = merchant === null || merchant === void 0 ? void 0 : merchant.ledger_tags) === null || _a === void 0 ? void 0 : _a[tagKey];
        return parseVirtualAccount(virtualAccount);
    }, [merchant === null || merchant === void 0 ? void 0 : merchant.ledger_tags, distributor === null || distributor === void 0 ? void 0 : distributor._id]);
    var locations = ((_e = (_d = merchant === null || merchant === void 0 ? void 0 : merchant.merchant_locations) === null || _d === void 0 ? void 0 : _d["".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id)]) === null || _e === void 0 ? void 0 : _e.filter(function (loc) { return loc.status === 'active' && !!loc._id; })) || [];
    var dashboard = useMerchantDashboard(merchant === null || merchant === void 0 ? void 0 : merchant._id, user === null || user === void 0 ? void 0 : user.distributor_id).data;
    return (_jsx(_Fragment, { children: _jsx(Card, { children: _jsxs(CardBody, __assign({ p: 0 }, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !merchant, errorSummary: "Merchant not found." }, void 0), !isLoading && !!merchant && (_jsx(_Fragment, { children: _jsxs(VStack, __assign({ align: "stretch", spacing: "0", divider: _jsx(Divider, { p: "0px", height: "0px" }, void 0) }, { children: [_jsxs(MerchantDetailGrid, __assign({ templateColumns: "48px 1fr auto" }, { children: [_jsx(Box, __assign({ justifySelf: "center" }, { children: _jsx(Avatar, { name: String(merchant.business_name.split(' ')[0]), size: "sm", borderRadius: "6px" }, void 0) }), void 0), _jsx(Text, __assign({ fontSize: "md", fontWeight: "semibold", noOfLines: 1 }, { children: merchant.business_name }), void 0), _jsx(Box, __assign({ justifySelf: "end", pr: "10px" }, { children: _jsx(Link, __assign({ target: "_blank", to: {
                                                    pathname: "tel:+234".concat((_f = merchant === null || merchant === void 0 ? void 0 : merchant.phone) === null || _f === void 0 ? void 0 : _f.slice(-10)),
                                                } }, { children: _jsx(Button, __assign({ size: "xs", variant: "ghost", colorScheme: "primary" }, { children: _jsx(RiPhoneFill, { size: "20px" }, void 0) }), void 0) }), void 0) }), void 0)] }), void 0), _jsxs(Box, { children: [_jsx(MerchantDetailRow, { label: _jsx(Text, __assign({ noOfLines: 1, color: "gray.600", fontSize: "sm", fontWeight: "semibold", textTransform: "uppercase" }, { children: "balance" }), void 0), containerProps: { pb: 0 } }, void 0), _jsx(MerchantDetailRow, { leftIcon: RiWalletFill, label: _jsx(Text, __assign({ fontSize: "md", fontWeight: "semibold", noOfLines: 1 }, { children: balance }), void 0), containerProps: { pt: '4px' } }, void 0)] }, void 0), _jsxs(Box, { children: [_jsx(MerchantDetailRow, { label: _jsx(Text, __assign({ noOfLines: 1, color: "gray.600", fontSize: "sm", fontWeight: "semibold", textTransform: "uppercase" }, { children: "target" }), void 0), containerProps: { pb: 0 } }, void 0), _jsx(MerchantDetailRow, { leftIcon: RiPieChart2Fill, label: _jsxs(Flex, __assign({ align: "center", gridGap: 2 }, { children: [_jsx(Text, __assign({ fontSize: "md", fontWeight: "semibold", noOfLines: 1 }, { children: formatCurrency((dashboard === null || dashboard === void 0 ? void 0 : dashboard.sales_target) || 0, {
                                                            showCurrency: true,
                                                        }) }), void 0), _jsxs(Text, __assign({ fontSize: "md", color: "gray.700" }, { children: ["vs.", ' ', formatCurrency((dashboard === null || dashboard === void 0 ? void 0 : dashboard.sales) || 0, {
                                                                showCurrency: true,
                                                            })] }), void 0)] }), void 0), containerProps: { pt: '4px', pb: '2px' } }, void 0), _jsx(MerchantDetailRow, { label: _jsxs(Flex, __assign({ fontSize: "sm", gridGap: 1 }, { children: [_jsxs(Badge, { children: [(dashboard === null || dashboard === void 0 ? void 0 : dashboard.achieved_pc) || 0, "%"] }, void 0), _jsx(Text, { children: "achieved" }, void 0)] }), void 0), containerProps: { pt: '0px', mt: '0px' } }, void 0)] }, void 0), !miniView && (_jsx(Box, { children: _jsxs(CollapsibleMerchantDetail, __assign({ label: "Show account details", leftIcon: RiBankFill, rightIcon: FiChevronRight }, { children: [_jsxs(MerchantDetailGrid, __assign({ pb: 0 }, { children: [_jsx(Box, {}, void 0), _jsx(Text, __assign({ noOfLines: 1, color: "gray.600", fontSize: "sm", fontWeight: "semibold", textTransform: "uppercase" }, { children: "account" }), void 0)] }), void 0), _jsx(MerchantDetailRow, { containerProps: {
                                                    pt: '4px',
                                                    alignItems: 'start',
                                                    templateColumns: '48px 1fr auto',
                                                }, leftIcon: RiBankFill, label: _jsxs(Box, __assign({ fontSize: "md", wordBreak: "break-all" }, { children: [_jsxs(Text, __assign({ fontWeight: "semibold" }, { children: [virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.account_number, " (", (_g = virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.bank) === null || _g === void 0 ? void 0 : _g.name, ")"] }), void 0), _jsx(Text, { children: virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.account_name }, void 0)] }), void 0), rightComponent: _jsx(Button, __assign({ size: "sm", colorScheme: "primary", variant: "link", mx: "20px", leftIcon: _jsx(FiCopy, { size: 16 }, void 0), onClick: function () {
                                                        var _a, _b;
                                                        (_b = (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText) === null || _b === void 0 ? void 0 : _b.call(_a, virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.account_number).then(function () {
                                                            toast({
                                                                status: 'info',
                                                                description: 'Account number copied!',
                                                            });
                                                        }).catch(function () {
                                                            toast({
                                                                status: 'warning',
                                                                description: 'Unable to copy',
                                                            });
                                                        });
                                                    } }, { children: "Copy" }), void 0) }, void 0)] }), void 0) }, void 0)), !miniView && (_jsx(Box, { children: _jsxs(CollapsibleMerchantDetail, __assign({ label: "Show locations", leftIcon: RiMapPin2Fill, rightIcon: FiChevronRight }, { children: [_jsxs(MerchantDetailGrid, __assign({ pb: "6px" }, { children: [_jsx(Box, {}, void 0), _jsx(Text, __assign({ noOfLines: 1, color: "gray.600", fontSize: "sm", fontWeight: "semibold", textTransform: "uppercase" }, { children: "Locations" }), void 0)] }), void 0), locations.map(function (location) { return (_jsx(MerchantDetailRow, { label: location.address, leftIcon: RiMapPin2Fill, containerProps: {
                                                    pt: '0px',
                                                    pb: '6px',
                                                } }, location._id)); }), _jsx(Box, { h: "10px" }, void 0)] }), void 0) }, void 0))] }), void 0) }, void 0))] }), void 0) }, void 0) }, void 0));
};
MerchantDetails.defaultProps = {
    miniView: false,
};
var MerchantDetailGrid = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (_jsx(SimpleGrid, __assign({ py: "10px", alignItems: "center", templateColumns: "48px 1fr 48px" }, props, { children: children }), void 0));
};
var MerchantDetailRow = function (_a) {
    var LeftIcon = _a.leftIcon, RightIcon = _a.rightIcon, rightComponent = _a.rightComponent, action = _a.action, label = _a.label, rest = __rest(_a, ["leftIcon", "rightIcon", "rightComponent", "action", "label"]);
    return (_jsxs(MerchantDetailGrid, __assign({ onClick: action, alignContent: "center", color: "gray.700", cursor: action ? 'pointer' : undefined }, rest.containerProps, { children: [LeftIcon ? (_jsx(Center, __assign({ w: 18, h: 18, borderRadius: 18, justifySelf: "center", bg: "gray.50" }, { children: _jsx(LeftIcon, { size: "12px" }, void 0) }), void 0)) : (_jsx(Box, {}, void 0)), typeof label === 'string' ? _jsx(Text, __assign({ noOfLines: 1 }, { children: label }), void 0) : label, RightIcon ? (_jsx(Center, __assign({ w: 18, h: 18, borderRadius: 18, justifySelf: "center" }, { children: _jsx(RightIcon, { size: "14px" }, void 0) }), void 0)) : rightComponent ? (rightComponent) : (_jsx(Box, {}, void 0))] }), void 0));
};
var CollapsibleMerchantDetail = function (props) {
    var children = props.children, rest = __rest(props, ["children"]);
    var _a = useBoolean(false), more = _a[0], setMore = _a[1];
    return (_jsx(_Fragment, { children: !more ? (_jsx(MerchantDetailRow, __assign({}, rest, { action: setMore.toggle }), void 0)) : (children) }, void 0));
};
