var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OrderMetaTag, OrderTag, } from '@obtainly/models';
import { useMutateModel } from '@obtainly/http';
import { parseOrderMeta } from '@obtainly/utils';
export function useOrderUpdateTag(order, options) {
    var updateOrderTag = useMutateModel('order.update_tag');
    var mutate = function (newTag) {
        var _a, _b, _c;
        var meta = (order === null || order === void 0 ? void 0 : order.meta) || '';
        var metaObject = {};
        // set officer who placed the order
        if (newTag === OrderTag.Pending && (options === null || options === void 0 ? void 0 : options.officer)) {
            metaObject = __assign(__assign({}, metaObject), (_a = {}, _a[OrderMetaTag.PlacedBy] = options === null || options === void 0 ? void 0 : options.officer, _a));
        }
        // watchout for order cancellation and set who cancelled the order
        if (newTag === OrderTag.Cancelled) {
            if (options === null || options === void 0 ? void 0 : options.merchant) {
                var merchant_1 = {};
                Object.entries(options.merchant || {}).forEach(function (_a) {
                    var value = _a[0], key = _a[1];
                    if (['string', 'number'].includes(typeof value)) {
                        merchant_1[key] = value;
                    }
                });
                metaObject = __assign(__assign({}, metaObject), (_b = {}, _b[OrderMetaTag.CancelledBy] = merchant_1, _b));
            }
            if (options === null || options === void 0 ? void 0 : options.officer) {
                metaObject = __assign(__assign({}, metaObject), (_c = {}, _c[OrderMetaTag.CancelledBy] = options.officer, _c));
            }
        }
        var newMetaString = parseOrderMeta(order, metaObject).metaString;
        meta = newMetaString;
        if (order) {
            updateOrderTag.mutate({
                _id: order._id || '',
                old_tag: order.tag,
                new_tag: newTag,
                meta: meta,
            }, {
                onSuccess: function () { var _a; return (_a = options === null || options === void 0 ? void 0 : options.onSuccess) === null || _a === void 0 ? void 0 : _a.call(options, newTag); },
                onError: options === null || options === void 0 ? void 0 : options.onError,
            });
        }
    };
    return __assign(__assign({}, updateOrderTag), { mutate: mutate });
}
