var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import sortBy from 'lodash/sortBy';
import { useQueryModel } from '@obtainly/http';
import { Autocomplete } from 'src/core/components';
import { distributorAtom, useAtom } from 'src/core/store';
export var MerchantAutocomplete = function (props) {
    var value = props.value, onChange = props.onChange, officerId = props.officerId, rest = __rest(props, ["value", "onChange", "officerId"]);
    var distributor = useAtom(distributorAtom)[0];
    var merchants = useQueryModel(officerId
        ? 'merchant.read_by_distributor_officer'
        : 'merchant.read_by_distributor', officerId
        ? {
            distributor_id: (distributor === null || distributor === void 0 ? void 0 : distributor._id) || '',
            distributor_officer_id: officerId,
        }
        : { distributor_id: (distributor === null || distributor === void 0 ? void 0 : distributor._id) || '' }, officerId
        ? { enabled: !!(distributor === null || distributor === void 0 ? void 0 : distributor._id) && !!officerId }
        : { enabled: !!(distributor === null || distributor === void 0 ? void 0 : distributor._id) }).data;
    var merchantOptions = React.useMemo(function () {
        if (merchants === null || merchants === void 0 ? void 0 : merchants.length) {
            var options = [];
            if (!props.isMulti && !props.disableSelectAll) {
                options.push({ value: '*', label: 'All Customers' });
            }
            var filtered = merchants
                .filter(function (merchant) {
                return merchant.status === 'active';
            })
                .map(function (merchant) { return ({
                value: merchant._id,
                label: merchant.business_name,
            }); });
            return __spreadArray(__spreadArray([], options, true), sortBy(filtered, function (merchant) { return merchant.label; }), true);
        }
        return [];
    }, [merchants, props.isMulti, props.disableSelectAll]);
    return (_jsx(Autocomplete, __assign({ value: value, options: merchantOptions, placeholder: "Select customer", onChange: function (selected) {
            return onChange(selected, merchants === null || merchants === void 0 ? void 0 : merchants.find(function (merchant) { return merchant._id === selected.value; }));
        }, isActive: value && value.value !== '*' ? true : false, isDisabled: !(merchantOptions === null || merchantOptions === void 0 ? void 0 : merchantOptions.length) }, rest), void 0));
};
