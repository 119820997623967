var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { Card, CardBody, CardHeader, Layout } from 'src/core/components';
import { PasswordForm, ProfileForm } from '../components';
import { useLogout } from 'src/core/hooks';
import { configAtom, useAtom } from 'src/core/store';
export var Settings = function () {
    var doLogout = useLogout();
    var _a = useAtom(configAtom)[0], appVersion = _a.appVersion, appEnv = _a.appEnv;
    return (_jsx(Layout, __assign({ headerProps: { title: 'Settings' } }, { children: _jsxs(VStack, __assign({ spacing: 5, align: "stretch" }, { children: [_jsxs(Card, { children: [_jsx(CardHeader, { heading: "Personal Profile" }, void 0), _jsx(CardBody, { children: _jsx(ProfileForm, {}, void 0) }, void 0)] }, void 0), _jsxs(Card, { children: [_jsx(CardHeader, { heading: "Update Password" }, void 0), _jsx(CardBody, { children: _jsx(PasswordForm, {}, void 0) }, void 0)] }, void 0), _jsx(Button, __assign({ colorScheme: "critical", onClick: function () { return doLogout.mutate(); }, isLoading: doLogout.isLoading, isDisabled: doLogout.isLoading }, { children: "Logout" }), void 0), _jsx(Box, { children: _jsxs(Text, __assign({ textAlign: "center", fontSize: "sm", color: "gray.700", textTransform: "capitalize" }, { children: ["Version", ' ', _jsx(Text, __assign({ as: "span", fontFamily: "mono" }, { children: appVersion }), void 0), ' ', "(", appEnv, ")"] }), void 0) }, void 0)] }), void 0) }), void 0));
};
