var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useOnline } from '@obtainly/hooks';
import { RouteSwitcher, SuspenseLoader, ErrorBox, } from '@obtainly/ui/web-shared';
import { useAtom, authAtom, userIdAtom } from 'src/core/store';
import { useToast } from 'src/core/hooks';
import { analytics } from './core/utils';
import { AppInit } from './AppInit';
export var App = function (props) {
    var location = useLocation();
    var _a = useToast(), toast = _a.toast, closeAllToasts = _a.closeAllToasts;
    var isOnline = useOnline(function () { return closeAllToasts(); }, function () {
        toast({
            status: 'error',
            description: "You're not connected to the internet.",
            isClosable: true,
        });
    });
    var auth = useAtom(authAtom)[0];
    var userId = useAtom(userIdAtom)[0];
    // Identify user on page change
    var _b = React.useState(false), userIdentified = _b[0], setUserIdentifed = _b[1];
    React.useEffect(function () {
        if (userId) {
            analytics.identifyUser(userId);
            setUserIdentifed(true);
        }
    }, [location, userId]);
    // Track page view
    React.useEffect(function () {
        if (userIdentified) {
            setUserIdentifed(false);
            analytics.trackPageView({ '!Link': location.pathname });
        }
    }, [location, userIdentified]);
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "app" }, { children: [_jsx(AppInit, { isOnline: isOnline }, void 0), !isOnline ? (_jsx(ErrorBox, { type: "network", my: 150 }, void 0)) : (_jsx("main", { children: auth.isLoading ? (_jsx(SuspenseLoader, {}, void 0)) : (_jsx(RouteSwitcher, { isAuthenticated: !!auth.token, routes: props.routes }, void 0)) }, void 0))] }), void 0) }, void 0));
};
