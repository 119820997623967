var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Flex, Box, Text, Badge } from '@chakra-ui/react';
import { useQueryModel } from '@obtainly/http';
import { Card, CardBody, CardHeader, ContentLoader } from 'src/core/components';
import { formatCurrency, formatUnixTime } from '@obtainly/utils';
import { FiArrowDownLeft, FiArrowUpRight, FiFile } from 'react-icons/fi';
export var LedgerLogs = function (props) {
    var ledgerId = props.ledgerId, heading = props.heading;
    var _a = useQueryModel('ledger_log.read_by_ledger', { ledger_id: ledgerId }), logs = _a.data, isLoading = _a.isLoading;
    return (_jsxs(Card, { children: [_jsx(CardHeader, { heading: heading || 'My Transactions', border: 0 }, void 0), _jsxs(CardBody, __assign({ px: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !(logs === null || logs === void 0 ? void 0 : logs.length), errorSummary: "No transactions found" }, void 0), !isLoading && (logs === null || logs === void 0 ? void 0 : logs.length)
                        ? logs.map(function (log) { return _jsx(LedgerLogItem, { item: log }, log._id); })
                        : null] }), void 0)] }, void 0));
};
export var LedgerLogItem = function (_a) {
    var _b;
    var item = _a.item, showMerchant = _a.showMerchant;
    var description = '';
    var merchant = null;
    try {
        var json = JSON.parse(item.description);
        description = ((_b = json === null || json === void 0 ? void 0 : json.narration) === null || _b === void 0 ? void 0 : _b.split('|').join(' | ')) || '-';
        merchant = json === null || json === void 0 ? void 0 : json.merchant;
    }
    catch (error) {
        description = item.description;
    }
    var canShowMerchant = !!merchant && !!showMerchant;
    return (_jsx(_Fragment, { children: item.value ? (_jsxs(Flex, __assign({ px: "20px", py: "10px", alignItems: "flex-start", borderBottom: "1px solid", borderColor: "gray.100", justifyContent: "space-between" }, { children: [_jsxs(Flex, __assign({ alignItems: "flex-start", width: "75%" }, { children: [_jsx(Flex, __assign({ p: 2, mb: 1, bg: item.value < 0
                                ? 'critical.50'
                                : item.value > 0
                                    ? 'success.50'
                                    : 'gray.50', color: item.value < 0
                                ? 'critical.600'
                                : item.value > 0
                                    ? 'success.600'
                                    : 'gray.600', borderRadius: 99999 }, { children: item.value < 0 ? (_jsx(FiArrowUpRight, { size: 14 }, void 0)) : item.value > 0 ? (_jsx(FiArrowDownLeft, { size: 14 }, void 0)) : (_jsx(FiFile, { size: 14 }, void 0)) }), void 0), _jsxs(Box, __assign({ ml: 2 }, { children: [canShowMerchant ? (_jsxs(_Fragment, { children: [_jsx(Text, { children: merchant === null || merchant === void 0 ? void 0 : merchant.business_name }, void 0), _jsxs(Text, __assign({ wordBreak: "break-word", color: "gray.700" }, { children: [_jsx(Badge, { children: item.tag }, void 0), ' ', _jsx(Text, __assign({ as: "span" }, { children: description }), void 0)] }), void 0)] }, void 0)) : (_jsxs(Text, __assign({ wordBreak: "keep-all" }, { children: [_jsx(Text, __assign({ as: "span", textTransform: "capitalize" }, { children: item.tag }), void 0), ' ', "- ", _jsx(Text, __assign({ as: "span" }, { children: description }), void 0)] }), void 0)), _jsx(Text, __assign({ fontSize: "sm", color: "gray.600" }, { children: formatUnixTime(item === null || item === void 0 ? void 0 : item.created) }), void 0)] }), void 0)] }), void 0), _jsx(Text, __assign({ color: "gray.600", ml: 2 }, { children: formatCurrency(item.value) }), void 0)] }), item._id)) : null }, void 0));
};
