import { Login, Settings } from './pages';
export var userRoutes = [
    {
        id: 'settings',
        path: '/settings',
        component: Settings,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'login',
        path: '/login',
        component: Login,
        exact: true,
    },
];
export * from './components';
