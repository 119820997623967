var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Avatar } from '@chakra-ui/avatar';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import { useTheme } from '@chakra-ui/system';
import { Link } from 'react-router-dom';
import { useAtom, userAtom, distributorAtom } from 'src/core/store';
import { useRouter } from 'src/core/hooks';
export var DashboardHeader = function () {
    var _a, _b, _c;
    var sizes = useTheme().sizes;
    var router = useRouter();
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorAtom)[0];
    return (_jsx(_Fragment, { children: _jsx(Box, __assign({ as: "header", left: 0, width: "100%", zIndex: 31 }, { children: _jsx(Box, __assign({ px: "10px", color: "white", bg: "secondary.main" }, { children: _jsxs(Flex, __assign({ alignItems: "center", justifyContent: "space-between", h: sizes.layout.pageHeader }, { children: [_jsx(Link, __assign({ to: router.getLink('dashboard') }, { children: _jsx(Heading, __assign({ color: "white", fontSize: "xl", fontWeight: "bold", noOfLines: 1 }, { children: distributor === null || distributor === void 0 ? void 0 : distributor.name }), void 0) }), void 0), _jsx(Avatar, { size: "xs", name: (_c = (_b = (_a = user === null || user === void 0 ? void 0 : user.distributor) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.split(' ')) === null || _c === void 0 ? void 0 : _c[0], cursor: "pointer", onClick: function () { return router.go('settings'); }, borderRadius: "8px" }, void 0)] }), void 0) }), void 0) }), void 0) }, void 0));
};
