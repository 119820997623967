var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { FiPlus } from 'react-icons/fi';
import { Card, CardBody, ContentLoader, Layout } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
import { useAtom, userAtom, distributorAtom } from 'src/core/store';
import { useQueryModel } from '@obtainly/http';
import { OrdersList } from 'src/order';
export var Orders = function (props) {
    var router = useRouter('orders');
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorAtom)[0];
    var _a = useQueryModel('order.read_by_distributor_officer', {
        distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
        distributor_officer_id: user === null || user === void 0 ? void 0 : user._id,
    }, {
        enabled: !!(user === null || user === void 0 ? void 0 : user._id) && !!(user === null || user === void 0 ? void 0 : user.distributor_id),
    }), orders = _a.data, isLoading = _a.isLoading;
    return (_jsxs(Layout, __assign({ headerProps: { title: 'Orders' } }, { children: [_jsx(Button, __assign({ mt: 3, size: "sm", variant: "solid", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () {
                    return router.go('order', { id: 'new' }, { merchant_id: props === null || props === void 0 ? void 0 : props.merchantId });
                }, isFullWidth: true }, { children: "Place New Order" }), void 0), _jsx(Card, __assign({ mt: 4 }, { children: _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading ? _jsx(OrdersList, { orders: orders || [] }, void 0) : null] }), void 0) }), void 0)] }), void 0));
};
