import { Payments } from './pages';
export var statementRoutes = [
    {
        id: 'payments',
        path: '/payments',
        component: Payments,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';
