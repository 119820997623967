var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Flex, SimpleGrid, Text, Box } from '@chakra-ui/layout';
import { RiFileList2Fill, RiHome6Fill, RiShoppingBag2Fill, RiTodoFill, } from 'react-icons/ri';
import { useRouter } from 'src/core/hooks';
import { analytics } from 'src/core/utils';
export var Navigation = function () {
    // Click source is usually the name of the component
    var CLICK_SOURCE = 'Navigation';
    var router = useRouter();
    return (_jsxs(_Fragment, { children: [_jsxs(SimpleGrid, __assign({ bg: "white", height: "70px", position: "fixed", bottom: 0, width: "100%", boxShadow: "1px -1px 10px rgba(23, 28, 52, 0.06)", borderRadius: "10px 10px 0 0", columns: 4, zIndex: 2 }, { children: [_jsx(NavigationItem, { icon: RiHome6Fill, label: "Home", isActive: location.pathname === '/', onClick: function () {
                            analytics.trackClick({
                                '!Click ID': 'Home',
                                '!Click Source': CLICK_SOURCE,
                            });
                            router.go('dashboard');
                        } }, void 0), _jsx(NavigationItem, { icon: RiShoppingBag2Fill, label: "Place Order", isActive: location.pathname === '/orders/new', onClick: function () {
                            analytics.trackClick({
                                '!Click ID': 'Place Order',
                                '!Click Source': CLICK_SOURCE,
                            });
                            router.go('order', { id: 'new' });
                        } }, void 0), _jsx(NavigationItem, { icon: RiTodoFill, label: "Orders", isActive: location.pathname === '/orders', onClick: function () {
                            analytics.trackClick({
                                '!Click ID': 'Orders',
                                '!Click Source': CLICK_SOURCE,
                            });
                            router.go('orders');
                        } }, void 0), _jsx(NavigationItem, { icon: RiFileList2Fill, label: "Payments", isActive: location.pathname === '/payments', onClick: function () {
                            analytics.trackClick({
                                '!Click ID': 'Payments',
                                '!Click Source': CLICK_SOURCE,
                            });
                            router.go('payments');
                        } }, void 0)] }), void 0), _jsx(Box, { marginTop: "100px" }, void 0)] }, void 0));
};
var NavigationItem = function (props) {
    var Icon = props.icon, label = props.label, isActive = props.isActive, onClick = props.onClick;
    return (_jsxs(Flex, __assign({ cursor: "pointer", opacity: onClick ? 1 : 0.5, color: isActive ? 'primary.500' : 'gray.700', flexDir: "column", justifyContent: "center", alignItems: "center", onClick: onClick }, { children: [_jsx(Box, __assign({ bg: isActive ? 'primary.50' : 'transparent', borderRadius: 99999 }, { children: _jsx(Icon, { size: 20 }, void 0) }), void 0), _jsx(Text, __assign({ mt: 1, fontSize: "sm", fontWeight: "medium" }, { children: label }), void 0)] }), void 0));
};
