var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Flex } from '@chakra-ui/layout';
import { fromUnixTime } from 'date-fns';
import { useQueryModel } from '@obtainly/http';
import { Card, CardBody, ContentLoader, ListMaker, MonthYearPicker, } from 'src/core/components';
import { useAtom, userAtom, distributorAtom, authAtom } from 'src/core/store';
import { LedgerLogItem } from 'src/ledger';
export var PaymentHistory = function (props) {
    var _a, _b;
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorAtom)[0];
    var _c = useAtom(authAtom), activeDate = _c[0].activeDate, setAuth = _c[1];
    var payload = React.useMemo(function () {
        var date = fromUnixTime(activeDate);
        return {
            _id: (props === null || props === void 0 ? void 0 : props.merchantId) || (user === null || user === void 0 ? void 0 : user._id),
            distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
            month: date.getMonth() + 1,
            year: date.getFullYear(),
        };
    }, [activeDate, distributor, user, props.merchantId]);
    var _d = useQueryModel('distributor_officer.statement', payload), data = _d.data, isLoading = _d.isLoading;
    var transactions = ((_b = (_a = data === null || data === void 0 ? void 0 : data.payments) === null || _a === void 0 ? void 0 : _a.list) === null || _b === void 0 ? void 0 : _b.filter(function (item) { return Math.abs(item.value); })) || [];
    return (_jsxs(_Fragment, { children: [_jsx(Flex, __assign({ mb: 3, justifyContent: "flex-end" }, { children: _jsx(MonthYearPicker, { value: activeDate, onChange: function (newDate) {
                        return setAuth(function (state) { return (__assign(__assign({}, state), { activeDate: newDate })); });
                    } }, void 0) }), void 0), _jsx(Card, { children: _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !(transactions === null || transactions === void 0 ? void 0 : transactions.length), errorSummary: "No payments found" }, void 0), !isLoading && (transactions === null || transactions === void 0 ? void 0 : transactions.length) ? (_jsx(ListMaker, { data: transactions.slice().reverse(), component: _jsx(LedgerLogItem, { showMerchant: true }, void 0) }, void 0)) : null] }), void 0) }, void 0)] }, void 0));
};
