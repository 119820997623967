var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { VStack, Grid } from '@chakra-ui/layout';
import { useParams } from 'react-router';
import { OrderTag } from '@obtainly/models';
import { useOrder, useOrderUpdateTag, useProducts } from '@obtainly/hooks';
import { OrderPicker, OrderPreview } from '@obtainly/ui/web-mobile';
import { useToast } from 'src/core/hooks';
import { ContentLoader, Layout } from 'src/core/components';
import { useAtom, userAtom } from 'src/core/store';
import { analytics, Event } from 'src/core/utils';
import { MerchantDetails } from 'src/merchant';
import { OrderForm, OrderActions, OrderNotifications } from '../components';
import { useOrderStateMachine } from '../hooks';
export var Order = function () {
    var toast = useToast().toast;
    var orderId = useParams().id;
    var _a = useOrder(orderId), order = _a.order, editableOrder = _a.editableOrder, loadingOrder = _a.loadingOrder, refetchOrder = _a.refetchOrder, refetchingOrder = _a.refetchingOrder;
    var user = useAtom(userAtom)[0];
    // Handle order tag states
    var orderUpdateTagMutation = useOrderUpdateTag(order || null, {
        officer: user || null,
        onSuccess: function (newTag) {
            var isNewOrder = newTag === OrderTag.Pending;
            analytics.track(isNewOrder ? Event.OrderPlacement : Event.OrderUpdate, {
                order_id: order === null || order === void 0 ? void 0 : order._id,
                old_tag: order === null || order === void 0 ? void 0 : order.tag,
                new_tag: newTag,
            });
            refetchOrder === null || refetchOrder === void 0 ? void 0 : refetchOrder();
            toast({
                description: newTag === OrderTag.Pending
                    ? 'This order has been placed successfully'
                    : 'Order updated',
                status: 'success',
            });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        onError: function (_a) {
            var message = _a.message;
            refetchOrder;
            toast({ description: message, status: 'error' });
        },
    });
    var orderStates = useOrderStateMachine(order || null, orderUpdateTagMutation);
    var _b = useProducts({
        distributor_id: order === null || order === void 0 ? void 0 : order.distributor_id,
        merchant_id: order === null || order === void 0 ? void 0 : order.merchant_id,
    }), products = _b.products, loadingProducts = _b.loadingProducts;
    return (_jsxs(Layout, __assign({ headerProps: {
            title: (order === null || order === void 0 ? void 0 : order.tag) === OrderTag.Draft
                ? 'New Order'
                : "Order #".concat((order === null || order === void 0 ? void 0 : order.ref) || ''),
        } }, { children: [_jsx(ContentLoader, { isLoading: loadingOrder || loadingProducts }, void 0), !loadingOrder && !loadingProducts ? (_jsxs(_Fragment, { children: [order && orderStates ? (_jsx(OrderActions, { order: order, orderStates: orderStates, loadingOrder: loadingOrder, refetchingOrder: refetchingOrder }, void 0)) : null, _jsx(Grid, __assign({ my: 3, templateColumns: ['1fr', '1fr'], gridGap: 5 }, { children: _jsx(VStack, __assign({ spacing: 5, alignItems: "stretch" }, { children: order && products ? (_jsxs(_Fragment, { children: [order.tag !== OrderTag.Draft && (_jsx(OrderNotifications, { order: order }, void 0)), editableOrder && refetchOrder ? (_jsxs(_Fragment, { children: [!!order.merchant_id && (_jsx(MerchantDetails, { merchantId: order.merchant_id, miniView: true }, void 0)), _jsx(OrderPicker, { order: order, products: products, editableOrder: editableOrder, refetchOrder: refetchOrder, refetchingOrder: refetchingOrder }, void 0)] }, void 0)) : (_jsx(OrderPreview, { order: order }, void 0))] }, void 0)) : (_jsx(OrderForm, { order: order }, void 0)) }), void 0) }), void 0), order && orderStates ? (_jsx(OrderActions, { order: order, orderStates: orderStates, loadingOrder: loadingOrder, refetchingOrder: refetchingOrder }, void 0)) : null] }, void 0)) : null] }), void 0));
};
