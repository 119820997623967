import React from 'react';
import { fromUnixTime } from 'date-fns';
import { useQueryModel } from '@obtainly/http';
export function useMerchantDashboard(merchantId, distributorId, activeDate) {
    var payload = React.useMemo(function () {
        var date = activeDate ? fromUnixTime(activeDate) : new Date();
        return {
            _id: merchantId,
            distributor_id: distributorId,
            month: date.getMonth() + 1,
            year: date.getFullYear(),
        };
    }, [activeDate, merchantId, distributorId]);
    var _a = useQueryModel('merchant.dashboard', payload, {
        enabled: !!payload._id && !!payload.distributor_id,
    }), data = _a.data, isLoading = _a.isLoading;
    return {
        data: data,
        isLoading: isLoading,
    };
}
