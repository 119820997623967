var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Text, Flex } from '@chakra-ui/layout';
import { Card, CardBody } from 'src/core/components';
import { useAtom, userAtom } from 'src/core/store';
export var DashboardSalutation = function () {
    var user = useAtom(userAtom)[0];
    return (_jsx(_Fragment, { children: _jsx(Card, __assign({ color: "white", bg: "secondary.main", borderRadius: "0 0 15px 15px" }, { children: _jsx(CardBody, __assign({ borderTop: "0px solid", borderColor: "secondary.main", pt: 0, px: "10px" }, { children: _jsx(Flex, __assign({ alignItems: "center", justifyContent: "start", gridGap: 3 }, { children: _jsxs(Text, { children: ["Hello, ", user === null || user === void 0 ? void 0 : user.firstname] }, void 0) }), void 0) }), void 0) }), void 0) }, void 0));
};
