var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Box, SimpleGrid, VStack } from '@chakra-ui/layout';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, } from '@chakra-ui/modal';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMutateModel } from '@obtainly/http';
import { FormikField, FormGroup, FormikRadioGroup, ImageUploader, } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { useAtom, userAtom, configAtom } from 'src/core/store';
var productFormSchema = Yup.object({
    name: Yup.string()
        .required('Product name is required.')
        .min(3, 'Product name must be at least 3 characters.'),
    // description: Yup.string()
    //   .required('Product description is required.')
    //   .min(10, 'Product description must be at least 10 characters.'),
    status: Yup.string().required('Product status is required.'),
    sku_id: Yup.string().required('SKU ID is required.'),
    case_count: Yup.number().required('Product case count is required.'),
    mpu: Yup.number()
        .test('mpu-is-gt-zero', 'MPU must be greater than 0', function (value) {
        return value ? value > 0 : false;
    })
        .required('MPU field is required.'),
    price: Yup.number().required('Price field is required.'),
    weight: Yup.number().required('Weight field is required'),
    size: Yup.number().required('Size field is required'),
});
export var ProductForm = function (props) {
    var toast = useToast().toast;
    var user = useAtom(userAtom)[0];
    var defaultUploadId = useAtom(configAtom)[0].defaultUploadId;
    var action = props.action, product = props.product;
    var createProduct = useMutateModel('product.create');
    var updateProduct = useMutateModel('product.update');
    var uploadFile = useMutateModel('upload.upload');
    var initialValues = {
        sku_id: (product === null || product === void 0 ? void 0 : product.sku_id) || '',
        name: (product === null || product === void 0 ? void 0 : product.name) || '',
        // description: product?.description || '',
        price: (product === null || product === void 0 ? void 0 : product.price) || 0,
        case_count: (product === null || product === void 0 ? void 0 : product.case_count) || 0,
        mpu: (product === null || product === void 0 ? void 0 : product.mpu) || 0,
        weight: (product === null || product === void 0 ? void 0 : product.weight) || 0,
        size: (product === null || product === void 0 ? void 0 : product.size) || 0,
        status: (product === null || product === void 0 ? void 0 : product.status) || 'active',
        upload_id: (product === null || product === void 0 ? void 0 : product.upload_id) || defaultUploadId,
        distributor_id: user === null || user === void 0 ? void 0 : user.distributor_id,
        // extra field for minimum purchasable cases
        cases: (product === null || product === void 0 ? void 0 : product.case_count) && (product === null || product === void 0 ? void 0 : product.mpu)
            ? (product === null || product === void 0 ? void 0 : product.mpu) / (product === null || product === void 0 ? void 0 : product.case_count)
            : 0,
    };
    var onSubmit = function (values) {
        values === null || values === void 0 ? true : delete values.cases;
        if (action === 'create') {
            createProduct.mutate(__assign({}, values));
        }
        else {
            updateProduct.mutate(__assign(__assign({}, values), { _id: product === null || product === void 0 ? void 0 : product._id }), {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: 'Product saved' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    var onUpload = function (file, values) {
        values === null || values === void 0 ? true : delete values.cases;
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('overwrite', 'true');
            formData.append('tag', 'product');
            formData.append('title', "".concat((product === null || product === void 0 ? void 0 : product._id) || values.sku_id));
            uploadFile.mutate(formData, {
                onSuccess: function (data) {
                    updateProduct.mutate(__assign(__assign({}, values), { upload_id: data, _id: product === null || product === void 0 ? void 0 : product._id }), {
                        onSuccess: function () {
                            toast({ status: 'success', description: 'Image uploaded' });
                            resolve();
                        },
                        onError: function (_a) {
                            var message = _a.message;
                            toast({ status: 'error', description: message });
                            reject();
                        },
                    });
                },
                onError: function (error) {
                    toast({ status: 'error', description: error.message });
                    reject(error.message);
                },
            });
        });
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: productFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var _b;
                        var formik = __rest(_a, []);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(ModalHeader, { children: [action === 'create' ? 'Add' : 'Update', " Product"] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsxs(ModalBody, { children: [action === 'update' && (_jsx(Box, __assign({ mb: 4 }, { children: _jsx(ImageUploader, { uploadPath: (_b = product === null || product === void 0 ? void 0 : product.upload) === null || _b === void 0 ? void 0 : _b.path, onUpload: function (_a) {
                                                    var file = _a.file;
                                                    return onUpload(file, __assign(__assign({}, formik.values), { upload_id: file._id }));
                                                } }, void 0) }), void 0)), _jsxs(VStack, __assign({ spacing: 5, align: "stretch" }, { children: [action === 'update' && (_jsx(FormGroup, __assign({ label: "Status" }, { children: _jsx(FormikRadioGroup, { name: "status", options: [
                                                            { value: 'active', label: 'Active' },
                                                            { value: 'inactive', label: 'Inactive' },
                                                        ] }, void 0) }), void 0)), _jsx(FormGroup, __assign({ label: "Name" }, { children: _jsx(FormikField, { size: "sm", name: "name", placeholder: "Product Name" }, void 0) }), void 0), _jsxs(SimpleGrid, __assign({ columns: [1, 2], spacingX: "10px" }, { children: [_jsx(FormGroup, __assign({ label: "SKU ID" }, { children: _jsx(FormikField, { size: "sm", name: "sku_id", placeholder: "ex. SAP, Sage" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Price" }, { children: _jsx(FormikField, { size: "sm", name: "price", prepend: "NGN" }, void 0) }), void 0)] }), void 0), _jsxs(SimpleGrid, __assign({ columns: [1, 2], spacingX: "10px" }, { children: [_jsx(FormGroup, __assign({ label: "Case Count" }, { children: _jsx(FormikField, { size: "sm", name: "case_count", onChange: function (_a) {
                                                                    var target = _a.target;
                                                                    formik.setFieldValue('case_count', target.value);
                                                                    formik.setFieldValue('mpu', Number(target.value) *
                                                                        Number(formik.values.cases || 0));
                                                                }, type: "number" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "MoQ (cases)" }, { children: _jsx(FormikField, { size: "sm", name: "cases", isDisabled: formik.values.case_count < 1, onChange: function (_a) {
                                                                    var target = _a.target;
                                                                    formik.setFieldValue('cases', target.value);
                                                                    formik.setFieldValue('mpu', Number(target.value) *
                                                                        Number(formik.values.case_count));
                                                                } }, void 0) }), void 0)] }), void 0), _jsxs(SimpleGrid, __assign({ columns: [1, 2], spacingX: "10px" }, { children: [_jsx(FormGroup, __assign({ label: "Weight" }, { children: _jsx(FormikField, { size: "sm", name: "weight" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Size" }, { children: _jsx(FormikField, { size: "sm", name: "size" }, void 0) }), void 0)] }), void 0)] }), void 0)] }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsxs(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: createProduct.isLoading || updateProduct.isLoading, isDisabled: createProduct.isLoading ||
                                                updateProduct.isLoading ||
                                                !formik.dirty ||
                                                !formik.isValid }, { children: [action === 'create' ? 'Add' : 'Update', " Product"] }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};
ProductForm.defaultProps = {
    action: 'create',
};
