var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { setSessionToken, unsetSessionToken, useMutationMaker, useQueryMaker, } from '@obtainly/http';
import { useAtom, authAtom, userAtom, permissionsAtom, userIdAtom, distributorAtom, configAtom, } from 'src/core/store';
import { analytics, Event } from '../utils';
export function useAuth(permissions, isOnline) {
    var _a = useAtom(authAtom), _b = _a[0], token = _b.token, refreshedAt = _b.refreshedAt, setAuth = _a[1];
    var _c = useAtom(userAtom), setUser = _c[1];
    var _d = useAtom(permissionsAtom), setPermissions = _d[1];
    var _e = useAtom(userIdAtom), setUserId = _e[1];
    var _f = useAtom(distributorAtom), setDistributor = _f[1];
    var appVersion = useAtom(configAtom)[0].appVersion;
    setSessionToken(token);
    var collatePermissions = function (role) {
        var _permissions = permissions
            .filter(function (permission) {
            return permission.roles.includes(role);
        })
            .map(function (permission) { return permission.id; });
        return _permissions;
    };
    var model = useQueryMaker({
        model: 'administrator',
        action: 'read_delegate',
        method: 'get',
        params: { token: token || null },
    }, {
        enabled: isOnline && !!token,
        queryKey: [token],
        onSuccess: function (data) {
            var _a;
            if (data && data.found) {
                var token_1 = data.token, profile = data.profile, role = data.role;
                // Identify user
                analytics.identifyUser(profile === null || profile === void 0 ? void 0 : profile._id);
                var userType = role ? (_a = role.split('.')) === null || _a === void 0 ? void 0 : _a[0] : null;
                var distributor = profile.distributor;
                if (userType === 'sales-officer') {
                    // Set profile properties
                    analytics.setUser({
                        $name: "".concat(profile.firstname, " ").concat(profile.lastname),
                        $email: profile.email,
                        'Officer Phone': profile.phone,
                        Supplier: distributor === null || distributor === void 0 ? void 0 : distributor.name,
                        'App Version': appVersion,
                    });
                    Sentry.setUser({
                        id: profile.user_id,
                        Name: "".concat(profile.firstname, " ").concat(profile.lastname),
                        email: profile.email,
                        Supplier: distributor === null || distributor === void 0 ? void 0 : distributor.name,
                        'App Version': appVersion,
                        ip_address: '{{auto}}',
                        Role: role,
                        'User ID': profile === null || profile === void 0 ? void 0 : profile.user_id,
                        'Officer ID': profile === null || profile === void 0 ? void 0 : profile._id,
                        'Distributor ID': distributor === null || distributor === void 0 ? void 0 : distributor._id,
                    });
                    setAuth(function (state) { return (__assign(__assign({}, state), { token: token_1 })); });
                    setUser(__assign(__assign({}, profile), { role: role }));
                    setPermissions(collatePermissions(role));
                    setUserId(profile._id);
                    setDistributor(distributor);
                }
                else {
                    unsetSessionToken();
                }
            }
            else {
                unsetSessionToken();
                setAuth(function (payload) { return (__assign(__assign({}, payload), { token: null })); });
            }
        },
        onError: function (error) {
            var message = error.message.toLowerCase();
            var isNetworkError = message.includes('network error');
            if (!isNetworkError) {
                unsetSessionToken();
                setAuth(function (payload) { return (__assign(__assign({}, payload), { token: null })); });
            }
        },
    });
    useEffect(function () {
        refreshedAt && model.refetch();
    }, [refreshedAt]); // eslint-disable-line
    useEffect(function () {
        setAuth(function (state) { return (__assign(__assign({}, state), { isLoading: model.isLoading })); });
    }, [model.isLoading]); // eslint-disable-line
    return {
        isAuthenticating: model.isLoading,
    };
}
export function useLogin() {
    var _a = useAtom(authAtom), setAuth = _a[1];
    var doLogin = useMutationMaker({
        model: 'administrator',
        action: 'login_delegate',
        method: 'post',
    }, {
        onSuccess: function (data) {
            var token = data.token;
            setSessionToken(token);
            setAuth(function (state) { return (__assign(__assign({}, state), { token: token })); });
            // Run after auth store is updated, so peradventure the user is identified.
            // We need to know the user performing the event.
            analytics.track(Event.Login);
        },
    });
    return doLogin;
}
export function useLogout() {
    var _a = useAtom(authAtom), token = _a[0].token, setAuth = _a[1];
    var doLogout = useMutationMaker({
        model: 'user_session',
        action: 'invalidate',
        method: 'get',
        params: {
            token: token || null,
        },
    }, {
        onSettled: function () {
            analytics.track(Event.Logout);
            unsetSessionToken();
            setAuth(function (state) { return (__assign(__assign({}, state), { token: null })); });
            Sentry.setUser(null);
        },
    });
    return doLogout;
}
